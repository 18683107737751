import React, { useState, useEffect } from "react";
import { graphql } from "gatsby";
import { Helmet } from "react-helmet";
import FlexibleContent from "../components/FlexibleContent";
import SEO from "../components/seo/SEO";
import Layout from "../layouts";
import { useSiteMetadata } from "../hooks/use-site-metadata";
import Modal from "react-modal";
import Cookies from "js-cookie";
//import wfhHero from '../images/wfh-hero.jpg';
//import wfhCollage from '../images/wfh-collage.jpg';

import { pageTitle } from "../utils/helpers";
import {
  orgSchema,
  localSchemaSF,
  localSchemaMTV,
  localSchemaSandpoint,
  localSchemaNY,
  localSchemaDublin,
  localSchemaLondon,
  localSchemaMexico,
} from "../utils/seoSchemas";

function PageTemplate(props) {
  const [modalIsOpen, setIsOpen] = useState(false);
  const [announcement, setAnnouncement] = useState(() => {
    const initialState = Cookies.get("announcement");
    return initialState;
  });

  useEffect(() => {
    if (!announcement || announcement !== "hide") {
      setIsOpen(true);
    }
  }, [announcement]);

  function closeModal() {
    Cookies.set("announcement", "hide", { expires: 1, path: "" });
    setAnnouncement(Cookies.get("announcement"));
    setIsOpen(false);
  }

  const currentPage = props.data.wordpressPage;
  const site = useSiteMetadata();

  const currentPageTitle = currentPage.yoast_meta.yoast_wpseo_title
    ? currentPage.yoast_meta.yoast_wpseo_title
    : pageTitle({
        site: site.title,
        page: currentPage,
      });
  const isHome = currentPage.slug === "home" ? true : false;

  return (
    <Layout>
      <Helmet bodyAttributes={{ id: `page-${currentPage.slug}` }}>
        <title>{currentPageTitle}</title>

        {/* Import Org Schema */}
        {isHome && (
          <script type="application/ld+json">
            {JSON.stringify(orgSchema)}
          </script>
        )}
        {/* Import all Local Schemas (until we have page per location) */}
        {isHome && (
          <script type="application/ld+json">
            {JSON.stringify(localSchemaSF)}
          </script>
        )}
        {isHome && (
          <script type="application/ld+json">
            {JSON.stringify(localSchemaMTV)}
          </script>
        )}
        {isHome && (
          <script type="application/ld+json">
            {JSON.stringify(localSchemaSandpoint)}
          </script>
        )}
        {isHome && (
          <script type="application/ld+json">
            {JSON.stringify(localSchemaNY)}
          </script>
        )}
        {isHome && (
          <script type="application/ld+json">
            {JSON.stringify(localSchemaDublin)}
          </script>
        )}
        {isHome && (
          <script type="application/ld+json">
            {JSON.stringify(localSchemaLondon)}
          </script>
        )}
        {isHome && (
          <script type="application/ld+json">
            {JSON.stringify(localSchemaMexico)}
          </script>
        )}
      </Helmet>
      <SEO node={currentPage} site={site} />
      <FlexibleContent currentPage={currentPage} />
      {isHome && (
        <Modal
          isOpen={modalIsOpen}
          onRequestClose={closeModal}
          contentLabel="Announcement"
          overlayClassName="announcement-modal-overlay"
          className="announcement-modal-content"
          ariaHideApp={false}
        >
          <div className="announcement-container">
            <div className="wfh-hero">
              <div className="wfh-hero-text">
                <p>
                  Firewood was founded on strong values, solid partnerships and
                  the belief that good people are good business. Our
                  values—humility, authenticity, inclusivity and
                  responsibility—have always guided our day-to-day work and the
                  decisions we make.
                </p>

                <p>
                  Since day one, our intent was to build something more valuable
                  than business, something that has the potential to change the
                  heart of our industry. And our “one team” culture of
                  integration built on transparency, flexibility and
                  collaboration—the embedded model—is just that.
                </p>

                <p>
                  In October 2019 we joined forces with the S4 Capital family of
                  companies, like-minded partners who share our values and our
                  vision. Now, we look forward to the future full of enthusiasm
                  for what’s ahead—for our people, for our clients, and for the
                  industry-at-large—under our unified brand, Media.Monks. Join
                  us as we embark on a new adventure at{" "}
                  <a href="https://media.monks.com/">media.monks.com</a>.
                </p>
              </div>
            </div>
          </div>
        </Modal>
      )}
    </Layout>
  );
}

export default PageTemplate;

export const pageQuery = graphql`
  query($id: String!) {
    wordpressPage(id: { eq: $id }) {
      title
      slug
      ...Body
      ...Hero
      ...Info
      ...Grid
      ...Interstitial
      ...Benefits
      ...Blocks
      ...SEO
    }
  }
`;
