export const orgSchema = {
  '@context': 'http://schema.org',
  '@type': 'Organization',
  name: 'Firewood',
  url: 'https://firewoodmarketing.com/',
  logo: 'https://storage.googleapis.com/fwm-site/fwm-logo-schema.png',
  contactPoint: [
    {
      '@type': 'ContactPoint',
      telephone: '+1-415-872-5132',
      contactType: 'customer support'
    }
  ],
  sameAs: [
    'https://www.linkedin.com/company/firewood-agency/',
    'https://www.instagram.com/firewoodmarketing/',
    'https://www.facebook.com/Firewoodmarketing',
    'https://twitter.com/firewoodmkt'
  ]
};

export const localSchemaSF = {
  '@context': 'https://schema.org',
  '@type': 'LocalBusiness',
  image: ['https://storage.googleapis.com/fwm-site/fwm-logo-schema.png'],
  '@id': 'https://firewoodmarketing.com/',
  name: 'Firewood',
  telephone: '+1-415-872-5132',
  address: {
    '@type': 'PostalAddress',
    streetAddress: '23 Geary St Suite 700',
    addressLocality: 'San Francisco',
    addressRegion: 'CA',
    postalCode: '94108',
    addressCountry: 'US'
  },
  geo: {
    '@type': 'GeoCoordinates',
    latitude: 37.787817,
    longitude: -122.403984
  }
};
export const localSchemaMTV = {
  '@context': 'https://schema.org',
  '@type': 'LocalBusiness',
  image: ['https://storage.googleapis.com/fwm-site/fwm-logo-schema.png'],
  '@id': 'https://firewoodmarketing.com/',
  name: 'Firewood',
  telephone: '+1-650-386-0078',
  address: {
    '@type': 'PostalAddress',
    streetAddress: '1215 Terra Bella Ave',
    addressLocality: 'Mountain View',
    addressRegion: 'CA',
    postalCode: '94043',
    addressCountry: 'US'
  },
  geo: {
    '@type': 'GeoCoordinates',
    latitude: 37.4082549,
    longitude: -122.0789229
  }
};

export const localSchemaSandpoint = {
  '@context': 'https://schema.org',
  '@type': 'LocalBusiness',
  image: ['https://storage.googleapis.com/fwm-site/fwm-logo-schema.png'],
  '@id': 'https://firewoodmarketing.com/',
  name: 'Firewood',
  address: {
    '@type': 'PostalAddress',
    streetAddress: '102 S. 1st Ave., Ste. 201',
    addressLocality: 'Sandpoint',
    addressRegion: 'ID',
    postalCode: '83864',
    addressCountry: 'US'
  },
  geo: {
    '@type': 'GeoCoordinates',
    latitude: 48.2722711,
    longitude: -116.5479152
  }
};

export const localSchemaNY = {
  '@context': 'https://schema.org',
  '@type': 'LocalBusiness',
  image: ['https://storage.googleapis.com/fwm-site/fwm-logo-schema.png'],
  '@id': 'https://firewoodmarketing.com/',
  name: 'Firewood',
  address: {
    '@type': 'PostalAddress',
    streetAddress: '115 W. 18th St.',
    addressLocality: 'New York',
    addressRegion: 'NY',
    postalCode: '10011',
    addressCountry: 'US'
  },
  geo: {
    '@type': 'GeoCoordinates',
    latitude: 40.7401457,
    longitude: -73.9954736
  }
};

export const localSchemaDublin = {
  '@context': 'https://schema.org',
  '@type': 'LocalBusiness',
  image: ['https://storage.googleapis.com/fwm-site/fwm-logo-schema.png'],
  '@id': 'https://firewoodmarketing.com/',
  name: 'Firewood',
  address: {
    '@type': 'PostalAddress',
    streetAddress: '24A Upper Baggot St',
    addressLocality: 'Dublin',
    addressRegion: 'County Dublin',
    postalCode: '4',
    addressCountry: 'IE'
  },
  geo: {
    '@type': 'GeoCoordinates',
    latitude: 53.333338,
    longitude: -6.243464
  }
};

export const localSchemaLondon = {
  '@context': 'https://schema.org',
  '@type': 'LocalBusiness',
  image: ['https://storage.googleapis.com/fwm-site/fwm-logo-schema.png'],
  '@id': 'https://firewoodmarketing.com/',
  name: 'Firewood',
  address: {
    '@type': 'PostalAddress',
    streetAddress: '10 Bloomsbury Way',
    addressLocality: 'London',
    addressRegion: 'Camden',
    postalCode: 'WC1A 2SL',
    addressCountry: 'GBR'
  },
  geo: {
    '@type': 'GeoCoordinates',
    latitude: 51.517638,
    longitude: -0.123907
  }
};

export const localSchemaMexico = {
  '@context': 'https://schema.org',
  '@type': 'LocalBusiness',
  image: ['https://storage.googleapis.com/fwm-site/fwm-logo-schema.png'],
  '@id': 'https://firewoodmarketing.com/',
  name: 'Firewood',
  address: {
    '@type': 'PostalAddress',
    streetAddress: 'Calz. Gral. Mariano Escobedo 595',
    addressLocality: 'Col. Bosque De Chapultepec I Sección Miguel Hidalgo',
    addressRegion: 'CIUDAD DE MÉXICO',
    postalCode: '11580',
    addressCountry: 'MX-CMX'
  },
  geo: {
    '@type': 'GeoCoordinates',
    latitude: 19.429063,
    longitude: -99.180469
  }
};
